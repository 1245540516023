<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>
            <v-row>
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="selectedStatuses"
                  :items="campaignStatuses[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('Status')"
                  multiple
                  clearable
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="selectedStatuses = handleRemoveFromArray(selectedStatuses, data.item.key)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="selectedTypes"
                  :items="campaignTypes[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('CampaingType')"
                  multiple
                  clearable
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="selectedTypes = handleRemoveFromArray(selectedTypes, data.item.key)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="selectedLocations"
                  :items="campaignLocations[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('Location')"
                  multiple
                  clearable
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="selectedLocations = handleRemoveFromArray(selectedLocations, data.item.key)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-card class="w-full">
          <v-card-text>
            <v-tabs vertical>
              <v-tab>
                {{ $t('Total') }}
              </v-tab>
              <v-tab>
                {{ $t('Geo') }}
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <total-widget
                      :date-from="transferDateFrom"
                      :date-to="transferDateTo"
                      :locations="transferLocations"
                      :campaing-types="transferCampaingTypes"
                      :statuses="transferStatuses"
                    ></total-widget>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <geo-widget
                  :date-from="transferDateFrom"
                  :date-to="transferDateTo"
                  :locations="transferLocations"
                  :campaing-types="transferCampaingTypes"
                  :statuses="transferStatuses"
                ></geo-widget>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dataFromDb"
          item-key="advertiser_id"
          class="elevation-1"
          :sort-by.sync="orderBy"
          :sort-desc.sync="sortDesc"
          multi-sort
          :search="search"
          :loading="loading"
          :locale="$i18n.locale"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              :label="$t('LblSearch')"
              class="mx-4"
            ></v-text-field>
          </template>

          <template #[`item.advertiserName`]="{item}">
            <v-row>
              <v-col>
                <v-btn
                  outlined
                  color="primary"
                  @click="goToCampaignList(item.advertiserId)"
                >
                  {{ item.advertiserName }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template #[`item.campaigns_count`]="{item}">
            <v-row class="mt-1 mb-1">
              <v-col>
                <v-row>
                  <v-col>
                    <v-chip
                      color="success"
                      small
                    >
                      {{ $t('ActiveCampigns') + ': ' + item.activeCount }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-chip
                      color="warning"
                      small
                    >
                      {{ $t('PausedCampigns') + ': ' + item.suspendedCount }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-chip
                      color="error"
                      small
                    >
                      {{ $t('InactiveCampigns') + ': ' + item.finishedCount }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline, mdiAccessPoint, mdiMicrosoftExcel, mdiFileExcel } from '@mdi/js'
import qs from 'qs'
import TotalWidget from './Widgets/TotalWidget.vue'
import DateTimePicker from '../Components/DateTimePicker.vue'
import GeoWidget from './Widgets/GeoWidget.vue'

export default {
  components: {
    TotalWidget,
    DateTimePicker,
    GeoWidget,
  },
  data() {
    return {
      orderBy: ['views', 'advertiserName'],
      sortDesc: [true, false],
      search: '',
      calories: '',
      showUnactive: false,
      dataFromDb: [],
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      transferDateFrom: new Date().toISOString().substr(0, 10),
      transferDateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      icons: {
        mdiAccountOutline,
        mdiLockOutline,
        mdiAccessPoint,
        mdiMicrosoftExcel,
        mdiFileExcel,
      },

      transferLocations: ['desktop', 'mobile'],
      transferStatuses: ['active', 'suspended'],
      transferCampaingTypes: ['video', 'banner', 'native'],

      selectedStatuses: ['active', 'suspended', 'finished'],
      campaignStatuses: {
        en: [
          {
            name: 'Active',
            key: 'active',
          },
          {
            name: 'Paused',
            key: 'suspended',
          },
          {
            name: 'Inactive',
            key: 'finished',
          },
        ],
        ru: [
          {
            name: 'Активна',
            key: 'active',
          },
          {
            name: 'Приостановлена',
            key: 'suspended',
          },
          {
            name: 'Завершена',
            key: 'finished',
          },
        ],
      },
      selectedTypes: ['video', 'banner', 'native'],
      campaignTypes: {
        en: [
          {
            name: 'Video',
            key: 'video',
          },
          {
            name: 'Banners',
            key: 'banner',
          },
          {
            name: 'Native',
            key: 'native',
          },
        ],
        ru: [
          {
            name: 'Видео',
            key: 'video',
          },
          {
            name: 'Баннеры',
            key: 'banner',
          },
          {
            name: 'Нативная',
            key: 'native',
          },
        ],
      },
      selectedLocations: ['desktop', 'mobile'],
      campaignLocations: {
        en: [
          {
            name: 'Desktop',
            key: 'desktop',
          },
          {
            name: 'Mobile',
            key: 'mobile',
          },
        ],
        ru: [
          {
            name: 'Десктоп',
            key: 'desktop',
          },
          {
            name: 'Мобайл',
            key: 'mobile',
          },
        ],
      },
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('Advertiser'), value: 'advertiserName' },
        { text: this.$t('Shows'), value: 'views' },
        { text: this.$t('References'), value: 'clicks' },
        { text: this.$t('Сoverage'), value: 'uniqViews' },
        { text: this.$t('CTR'), value: 'ctr' },
        { text: this.$t('CampaignsCount'), value: 'campaigns_count' },
      ]
    },
  },
  mounted() {
    this.getData()

    // eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI1a2FAbW9iaWRyaXZlbi5jb20iLCJyb2xlcyI6WyJBRFZFUlRJU0VSUyJdLCJpYXQiOjE3MDA2NDk4NTcsImV4cCI6MTczMjE4NTg1N30.2Dkgw_l9vxQsUYqq2qIlbPuS9zutobV0oRa7J1gRsTw

    // this.$root.$on('update_city', this.getData())
  },
  methods: {
    goToReportGenerator(advertiserId) {
      this.$router.push({ name: 'advertisers-generate-report', params: { advertiser_id: advertiserId } })

      // this.$router.push({ name: 'campaigns-list', params: { advertiser_id: advertiserId } })
    },
    goToCampaignList(advertiserId) {
      this.$router.push({ name: 'campaigns-list', params: { advertiser_id: advertiserId } })

      // localStorage.setItem('advertiserId', advertiserId)
    },
    getData() {
      this.loading = true
      this.transferDateFrom = this.dateFrom
      this.transferDateTo = this.dateTo
      this.transferLocations = this.selectedLocations
      this.transferCampaingTypes = this.selectedTypes
      this.transferStatuses = this.selectedStatuses
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          userId: this.$getUserId(),
          statuses: this.selectedStatuses,
          locations: this.selectedLocations,
          campaingTypes: this.selectedTypes,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/table_data', params)
      this.$http.get(`${this.$apiBaseURL}/publisher/items`, params).then(response => {
        this.dataFromDb = response.data
        this.loading = false
      })
    },
    handleRemoveFromArray(array, element) {
      return array.filter(ele => ele !== element)
    },
  },
}
</script>
